import {Controller} from "@hotwired/stimulus";

// Connects to data-controller="mobile-menu"
export default class extends Controller {
  static targets = ["menu"];

  close() {
    this.menuTarget.classList.remove("opacity-100", "scale-100");
    this.menuTarget.classList.add("opacity-0", "scale-95");
    this.menuTarget.classList.add("hidden");
  }

  open() {
    this.menuTarget.classList.remove("opacity-0", "scale-95");
    this.menuTarget.classList.add("opacity-100", "scale-100");
    this.menuTarget.classList.remove("hidden");
  }
}
